import './style.css';

function Home() {
    return (
        <div className="hero">
          <div className="overlay-text">Coming Soon!</div>
        </div>
    );
}

export default Home;