import './style.css';

function Error() {
    return (
        <div className='ErrorPageContainer'>
            <h1>Error 404</h1>
            <p>The Page or Resource you are trying to access cannot be found.</p>
        </div>
    );
}

export default Error;