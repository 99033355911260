import { useState } from 'react';
import { BrowserRouter, Routes, Route, Link, Outlet } from 'react-router-dom';

import Error from './pages/Error';
import About from './pages/About';
import Home from './pages/Home';

import logoIcon from './assets/logo.png';
import navIcon from './assets/navIcon-50.png';

import './App.css';

const Logo = () => {
  return (
    <div className='LogoIconContainer'>
      <img
      src={logoIcon}
      alt='I Hair You'
      className='LogoIcon'
    />
    </div>
  )
}

const NavBar = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className='NavContainer'>
      <div className='Nav'>
        <img
          src={navIcon}
          alt='Nav Menu'
          className='NavIconImg' 
          onClick={toggleDropdown}
        />
        {showDropdown && (
          <div className='NavMenu'>
            <Link to='/' onClick={toggleDropdown}>Home</Link>
            <Link to='/about' onClick={toggleDropdown}>About Us</Link>
          </div>
        )}
      </div>
    </div>
  )
}

const Footer = () => {
  return (
    <div className='FooterContainer'>
      <p className='FooterText'>&copy; 2023 I Hair You LLC. All rights reserved. </p>
    </div>
  )
}

const Layout = () => {
  return (
    <div>
      <Logo />
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='*' element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
