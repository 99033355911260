import './style.css';

function About() {
    return (
        <div className="AboutPageContainer">
          <h1>About Us</h1>
          <p>Under Construction!</p>
        </div>
    );
}

export default About;